/* =============================== */
/*              intro              */
/* =============================== */
#intro {
    position: relative;
    height: 90%;
    width: 100%;
    line-height: 1.2;
    padding: 60px 0px 0px 0px;
    color: var(--white);
    font-family: var(--font-family-montserrat);
    display: flex;
    justify-content: center;
}

#intro video {
    width: 100%;
    height: 100%;
    margin: 0px;
    object-fit: contain;
}

#intro-left {
    position: absolute;
    top: 70%;
    left: 5.3%;
    width: 43%;
}

#intro h1 {
    font-size: 48px;
    font-weight: 700;
    width: 500px;
}

#intro-left .buttons {
    display: flex;
    align-items: center;
}

#intro-left .button {
    color: var(--white);
    text-decoration: none;
    border-radius: 0px;
    padding: 15px 0px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    transition: 0.5s;
    text-align: center;
    width: 100%;
    animation: pop linear 14s;
}

#intro-left .get-started {
    border: 2px solid var(--dark-blue);
    background-color: #4168a6;
    margin: 0px 20px 0px 0px;
}

#intro-left .more-about {
    color: black;
    border: 2px solid var(--dark-blue);
}

#intro-left .get-started:hover {
    border: 2px solid var(--dark-blue);
    color: black;
    background-color: rgba(0, 0, 0, 0);
}

#intro-left .more-about:hover {
    color: #fff;
    border: 2px solid var(--dark-blue);
    background-color: var(--dark-blue);
}

@keyframes pop {
    0% {
        opacity: 0;
        transform: translateX(20px);
    }

    30% {
        /* transform: scale(104%); */
        opacity: 0;
    }

    32% {
        opacity: 1;
        transform: translateX(40px);
    }

    100% {
        opacity: 1;
        transform: translateX(0px);
        /* color: red; */
    }

}

@keyframes pop1 {
    0% {
        opacity: 0;
    }

    90% {
        opacity: 0;
    }

    91% {
        opacity: 0.8;
    }

    95% {
        transform: scale(104%);
        /* opacity: 0.5; */
    }

    100% {
        opacity: 1;
    }

}

#intro-img {
    width: 50%
}

#intro-img>img {
    width: 100%;

}

/*              intro              */
/* ||||||||||||||||||||||||||||||| */



/* =============================== */
/*              main               */
/* =============================== */
#main {
    padding-bottom: 60px;
    background-color: var(--white);
    margin: 0;
}

#why-us h3 {
    font-family: var(--font-family-montserrat);
    font-size: 36px;
    font-weight: 500;
    margin-bottom: 20px;
    text-align: center;
}

#why-us p {
    text-align: center;
    font-size: 15px;
}

.card {
    background-color: var(--dark-blue);
    background: #00458f;
    border-color: #00458f;
    border-radius: 10px;
    margin: 30px 0px;
    color: #fff;
    width: 100%;
}

#why-us .card h5 {
    background-color: rgb(255, 255, 255);
    color: #00458f;
    font-size: 32px;
    font-weight: 600;
    /* margin: 15px 0px; */
    border-radius: 10px 10px 0px 0px;
    padding: 18px 0px;
    text-align: center;
    border: 1px solid #00458f87;
}

#why-us .card-text {
    padding: 20px;
}

#why-us .card p {
    font-size: 19px;
    color: #d8eafe;
    text-align: left;
    font-family: var(--font-family-montserrat);
}

#why-us .card strong {
    font-size: 21px;
    line-height: 3;
    font-style: italic;
}

.link-input-div {
    margin-top: 50px;
    background-color: var(--active-blue);
    display: flex;
    color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.5);
}

#link-input {
    width: 100%;
    padding: 15px 20px;
    border-radius: 10px;
    outline: none;
    border: 1px solid hsla(197, 7%, 21%, 0.425);
    font-size: 18px;
}

.link-input-div button {
    width: 200px;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    cursor: pointer;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 1px;
    font-family: var(--font-family-opensans);
}

.link-input-div button {
    width: 200px;
    background-color: rgba(0, 0, 0, 0);
    border: none;
}

iframe {
    margin-top: 20px;
    border-radius: 10px;
    width: 100%;
    aspect-ratio: 16/9;
    height: auto;
    object-fit: contain;
    /* background-color: #283d50; */
    /* border: 1px solid rgba(0, 0, 0, 0.862); */
}
ins{
    border: 0.5px solid rgba(0, 0, 0, 0.205);
}
.timer {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    background-color: var(--active-blue);
    padding: 10px 20px;
    color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.5);
    font-size: 18px;
    letter-spacing: 2px;
    font-weight: 600;
}

#player {
    margin-top: 20px;
    border-radius: 10px;
    width: 100%;
    aspect-ratio: 16/9;
    height: auto;
    object-fit: contain;
    background-color: #283d50;
}

button:disabled,
button[disabled] {
    /* border: 1px solid #999999; */
    /* background-color: #cccccc; */
    color: #0f4b83;
    cursor: not-allowed;
}

/*              main               */
/* ||||||||||||||||||||||||||||||| */



/* =============================== */
/*              get                */
/* =============================== */
#portfolio {
    padding: 60px 0;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
}

#portfolio h3 {
    text-align: center;
    font-weight: 500;
    font-size: 36px;
    color: #283d50;
    margin-bottom: 20px;
}

.strat1 {
    background-color: #007bff;
    border-radius: 28px;
    border: 1px solid #007bff;
    display: inline-block;
    cursor: pointer;
    color: #ffffff;
    font-family: Arial;
    font-size: 17px;
    padding: 16px 31px;
    text-decoration: none;
    text-shadow: 0px 1px 0px #007bff;
}

/*              get                */
/* ||||||||||||||||||||||||||||||| */

@media only screen and (min-width: 1630px) {
    #intro {
        margin: auto;
        width: 1590px;
    }
}

@media only screen and (max-width: 992px) {
    #intro h1 {
        font-size: 36px;
        width: 400px;

    }
}

@media only screen and (max-width: 768px) {

    .link-input-div {
        margin-top: 10px;
    }

    #intro-img {
        margin-top: 20px;
        width: 100%;
        display: flex;
        justify-content: center;
    }

    #intro-img>img {
        height: 100%;
        object-fit: contain;
        max-width: 301px;
        /* content: url("../images/back-Copy.png"); */
    }

    #intro h1 {
        font-size: 32px;
        width: 100%;
        padding: 0px 50px;
        text-align: center;

    }

    #intro-left {
        position: absolute;
        top: 90%;
        /* bottom: -10px; */
        left: 0%;
        width: 100%;
        padding: 0px 20px;
    }

    #intro-left .buttons {
        animation: pop1 linear 3s;
    }

    #intro-left .button {
        animation: none;
        font-size: 12px;
    }

    #why-us h3 {
        margin-top: 30px;
        font-size: 28px;
    }

    .card {
        margin: 30px auto;
        /* padding: 10px 15px; */
        width: 100%;
    }

    .fa {
        font-size: 36px;
        padding-top: 18px;
    }

    #why-us .card h5 {
        font-size: 26px;
    }

    #why-us .card p {
        font-size: 17px;
    }

    #why-us .card strong {
        font-size: 19px;
        line-height: 2.8;
    }

}

@media only screen and (max-width: 600px) {

    #intro h1 {
        font-size: 30px;
        padding: 0px 20px;
        text-align: center;
    }

    #why-us h3 {
        font-size: 26px;
    }

    #portfolio h3 {
        font-size: 20px;
        margin-bottom: 20px;
    }

    #intro-left .buttons {
        display: flex;
        /* flex-direction: column; */
        align-items: center;
        justify-content: center;
    }

    #intro-left .button {
        font-size: 12px;
        /* margin: 0px 0px 10px 0px; */
    }

    .strat1 {
        font-family: Arial;
        font-size: 14px;
        padding: 16px 31px;
    }

    .fa {
        font-size: 30px;
        padding-top: 18px;
    }

    #why-us .card h5 {
        font-size: 24px;
    }

    #why-us .card p {
        font-size: 16px;
    }

    #why-us .card strong {
        font-size: 17px;
        line-height: 2;
    }

    #link-input {
        width: 100%;
        padding: 10px 15px;
        border-radius: 10px;
        font-size: 14px;
    }

    .link-input-div button {
        width: 170px;
        font-weight: 600;
        font-size: 16px;
    }

    .timer {
        margin-top: 10px;
        padding: 10px 15px;
        font-size: 16px;
        letter-spacing: 2px;
        font-weight: 600;
    }

    #player {
        margin-top: 15px;
    }
}

@media only screen and (max-width: 425px) {
    .timer {
        flex-direction: column-reverse;
        line-height: 2;
        margin-top: 8px;
        padding: 8px 12px;
        font-size: 14px;
    }
}