#header {
    background-color: #ffffff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.3);
    position: fixed;
    width: 100%;
    z-index: 2;
}
.cursor-pointer{
    cursor: pointer;
}
.navbar {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-family: var(--font-family-montserrat);
    transition: 0.5s;
}

.navbar .logo {
    color: var(--active-blue);
    text-decoration: none;
    font-size: 24px;
    font-weight: 500;
}

#logo{
    height: 70%;
    /* padding: 20px; */
}
.navbar ul {
    display: flex;
    align-items: center;

}

.navbar .link {
    padding: 0px 16px;
    color: var(--blue);
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
}

.navbar .link:hover {
    color: var(--active-blue);
}

.navbar .active {
    color: var(--active-blue);
}

.mobile-menu {
    display: none;
}




.show {
    pointer-events: auto;
    opacity: 1;
    animation-name: left-to-right;
    animation-duration: 0.5s;
}

.hide {
    display: none;
    pointer-events: none;
    opacity: 0;
    animation-name: right-to-left;
    animation-duration: 0.5s;
}

.modal-container {
    padding-top: 70px;
    z-index: 1;
    background-color: var(--blue);
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 220px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    transition: 0.3s ease-in;
}

.modal-container .link {
    /* color: var(--blue); */
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
    font-family: var(--font-family-montserrat);
    display: block;
    width: 100%;
    padding: 12px;
    text-align: center;
    color: #ffffff;
    border-bottom: 1px solid #ffffff4d;
}

@keyframes left-to-right {
    0% {
        translate: -200px;
    }

    100% {
        translate: 0px;
    }
}
@keyframes right-to-left {
    0% {
        translate: 0px;
    }

    100% {
        translate: -200px;
    }
}

@media only screen and (max-width: 992px) {
    .navbar {
        height: 60px;
        display: flex;
        justify-content: space-between;
        padding: 0px 15px;
        transition: 0.5s;
    }

    .navbar .link {
        display: none;
    }

    .mobile-menu {
        display: contents;
        padding: 0px;
    }

    .menu {
        font-size: 36px;
        padding: 0px;
        color: var(--blue);
    }

    .navbar .logo {
        font-size: 22px;
    }
}

@media only screen and (max-width: 600px) {
    .navbar .logo {
        font-size: 20px;
    }
}