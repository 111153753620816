@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

* {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
}

:root {
  --active-blue: #5186d0;
  --blue: #004289;
  --dark-blue: #4168a6;
  --white: #fff;
  --font-family-montserrat: "Montserrat", sans-serif;
  --font-family-opensans: "Open Sans", sans-serif;
}

#root {
  min-height: 100vh !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
body{
  min-height: 100vh;
}
#spinner {
  height: 100%;
  margin: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-family-montserrat);
}

.flex {
  display: flex;
}

.container {
  width: 1140px;
  padding: 0px 15px;
  margin: auto;
}

.container2 {
  width: 100%;
  padding: 0px 0px;
}

.add {
  margin: 0px 25px;
  min-width: 160px;
 /* width: 160px; */
  /* min-height: 600px;  */
  /* height: 100%; */
  /* border: 1px solid rgba(0, 0, 0, 0.292);  */
  /* overflow: hidden; */
  
} 


.for-add {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 110px;
}

.capitalize {
  text-transform: capitalize;
}

.row {
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: 1200px) {
  .container {
    width: 940px;
  }
}

@media only screen and (max-width: 992px) {
  .container {
    width: 720px;
  }
}

@media only screen and (max-width: 768px) {
  .container {
    width: 100%;
    padding: 0px 20px;
  }

  .row {
    display: flex;
    flex-direction: column;

  }

  .flex {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .for-add {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px 10px;
    margin-top: 70px;
  }

  .add {
    margin: 25px 0px;
    width: 100%;
    min-width: 320px;
    /* height: 100px; */
  } 
}
