#alert{
    position: fixed;
    width: 100%;
    top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family-montserrat);
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    padding: 0px 10px;
}

.alert-div{
    min-height: 80px;
    min-width: 300px;
    padding: 20px ;
    background-color: #0090e0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    animation: alertAni 0.3s ease-in-out;
}

.red{
    background-color: #ff0f0fde;
}

.green{
    background-color: #14a148;
}

@keyframes alertAni {
    0%{
        opacity: 0;
        transform: translateY(-100px);
    }
    100%{
        opacity: 1;
    }
}
